import { Projeto } from "../components/Projeto";
import styles from "../styles/Projetos.module.scss";

import { useEffect, useState } from 'react';

import { Tooltip } from 'primereact/tooltip';
import { Header } from "../components/Header";
import { Background } from "../components/Background";
import axios from "axios";
import { domain } from "../constants";

export function Projetos() {
  const [projetos, setProjetos] = useState<any[]>([]);

  useEffect(() => {
    axios.get(domain + "projetos.json")
      .then(res => setProjetos(res.data));
  }, []);

  return (
    <>
      <Header />
      <Background />

      <div className={styles.container}>
        <h1>PROJETOS ESTRUTURADOS</h1>

        <div className={styles.fieldProjetos}>
          {projetos.map((projeto, index) => (
            <Projeto key={index} nome={projeto.nome} pdf_pratica={projeto.pdf_pratica} pdf_teoria={projeto.pdf_teoria} />
          ))}
        </div>

        <Tooltip target="#logo_ata" />
        <span id="logo_ata" data-pr-tooltip="Associação de Projetos de Gestão e Estruturas Acadêmicas">
          <h1 id="#logo" className={styles.subTitle}>APGEA</h1>
        </span>
      </div>
    </>
  )
};
