import axios from 'axios';
import { useEffect, useState } from 'react';
import { Background } from '../components/Background';
import { Header } from '../components/Header';
import { Tooltip } from 'primereact/tooltip';
import styles from '../styles/Instituicao.module.scss'
import { domain } from '../constants';

interface ArquivosProps {
  nome: string;
  arquivos: {
      nome: string;
      arquivo: string;
  }[];
}

export function Cnpj() {
  const [arquivos, setArquivos] = useState<ArquivosProps[]>([]);

  useEffect(() => {
    axios.get(domain + "arquivos.json")
      .then(res => setArquivos(res.data));
  }, []);

  return (
    <>
      <Header />
      <Background />

      <div className={styles.container}>
        <div className={styles.subContainer}>
          <h1>CNPJ</h1>

          <div className={styles.fieldDoc}>
            {arquivos.find(i => i.nome === "cnpj")?.arquivos.map((projeto, index) => (
              <div onClick={() => window.open(`https://apgmea.com/arquivos/${projeto.arquivo}`, '_blank')?.focus()} className={styles.fieldArquivoItem}>
                <i className='pi pi-angle-right' />
                <p>{projeto.nome}</p>
              </div>
            ))}
          </div>

          <Tooltip target="#logo_ata" />
          <span id="logo_ata" data-pr-tooltip="Associação de Projetos de Gestão e Estruturas Acadêmicas">
            <h1 id="#logo" className={styles.subTitle}>APGEA</h1>
          </span>
        </div>
      </div>
    </>
  )
}
