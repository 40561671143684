import { Background } from '../components/Background'
import { Header } from '../components/Header'
import { Tooltip } from 'primereact/tooltip';
import styles from '../styles/Home.module.scss'

export function Home() {
  return (
    <>
      <Header />
      <Background />
    
      <div className={styles.container}>
        <h1 className={styles.mainTitle}>Associação de Projetos de Gestão Pública, Meio Ambiente e Estruturas Acadêmicas</h1>

        <Tooltip target="#logo_ata" position='top' />
        <span id="logo_ata" data-pr-tooltip="Associação de Projetos de Gestão e Estruturas Acadêmicas">
          <h1 id="#logo" className={styles.subTitle}>APGEA</h1>
        </span>
      </div>
    </>
  )
}
