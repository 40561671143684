import styles from "./styles.module.scss";

function Background() {
  return (
    <div className={styles.container}>
      <section />
    </div>
  )
}

export { Background }
