import { useEffect, useState } from "react";

import axios from "axios";
import { Background } from "../components/Background";
import { Header } from "../components/Header";
import { Tooltip } from 'primereact/tooltip';

import styles from "../styles/Youtube.module.scss";

export function Youtube() {
  const [videos, setVideos] = useState<any[]>([]);

  useEffect(() => {
    axios.get("https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCX0m4qC0obPnaYw3WoEf5mg&maxResults=10&order=date&type=video&key=AIzaSyCUJJYA4QjvDkQ4GvZKA-HNYvTuUFYLu9A")
    .then(res => setVideos(res.data.items));
  }, []);

  function openInNewTab(url: string) {
    window.open(url, '_blank')?.focus();
  }

  return (
    <>
      <Header />
      <Background />

      <div className={styles.container}>
        <div className={styles.subContainer}>
          <h1>YOUTUBE</h1>

          <div className={styles.fieldDoc}>
            {[...videos].map((item, index) => (
              <div key={index} onClick={() => openInNewTab(`https://www.youtube.com/watch?v=${item.id.videoId}`)}>
                <h2>{item.snippet.title}</h2>
                <img src={item.snippet.thumbnails.high.url} alt="" />
              </div>
            ))}
          </div>
          
          <Tooltip target="#logo_ata" />
          <span id="logo_ata" data-pr-tooltip="Associação de Projetos de Gestão e Estruturas Acadêmicas">
            <h1 id="#logo" className={styles.subTitle}>APGEA</h1>
          </span>
        </div>
      </div>
    </>
  )
}