import {
  Route,
  HashRouter,
  Switch
} from "react-router-dom";
import { Home } from "./pages";
import { Ata } from "./pages/ata";
import { Cnpj } from "./pages/cnpj";
import { Estatuto } from "./pages/estatuto";
import { Projetos } from "./pages/projetos";
import { Youtube } from "./pages/youtube";

import "./styles/globals.css";

const Router = () => (
    <HashRouter>
      <Switch>        
        <Route path="/" exact component={Home} />
        <Route path="/ata" exact component={Ata} />
        <Route path="/estatuto" exact component={Estatuto} />
        <Route path="/cnpj" exact component={Cnpj} />
        <Route path="/projetos" exact component={Projetos} />
        <Route path="/youtube" exact component={Youtube} />
      </Switch>
    </HashRouter>
);

export { Router };
