import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from './Router';

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <>
      <Router />
    </>
  </React.StrictMode>
);
