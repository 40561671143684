import { useState } from "react";
import styles from "./styles.module.scss";

import { Dialog } from 'primereact/dialog'

interface ProjetoPraticaProps {
  nome: string;
  url: string;
}

interface ProjetoProps {
  nome: string;
  pdf_teoria: string;
  pdf_pratica: ProjetoPraticaProps[];
}

function Projeto({ nome, pdf_pratica, pdf_teoria }: ProjetoProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Dialog header={"Prática"} draggable={false} visible={visible} onHide={() => setVisible(false)}>
        <div className={styles.praticaPdfField}>
          {pdf_pratica.map((praticaItem, index) => (
            <button
              className={styles.praticaPdfButton}
              key={index}
              onClick={() => {
                window.open(praticaItem.url)
              }}
            >
              {praticaItem.nome}
            </button>
          ))}
        </div>
      </Dialog>

      <div id={nome} className={styles.container}>
        <h1>{nome}</h1>

        <div className={styles.fieldButtons}>
          <a target="_blank" rel="noreferrer" href={pdf_teoria}>Teórico</a>
          <button onClick={() => setVisible(true)}>Estrutura Prática</button>
        </div>
      </div>
    </>
  );
};

export { Projeto };
